import * as React from 'react';
import { useNavigate } from 'react-router-dom'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {ResponsiveDialog, FormDialog} from './Dialog';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { getRole} from './util';
import { getUsers, deleteUser, updateUser} from '../services/Service';


export default function UsersList() {


    let navigate = useNavigate()
    const [page, setPage] = React.useState(1);
    const [pageCount, setPageCount] = React.useState(1);
    const [usersList, setUsersList] = React.useState();
    const [toDelete, setToDelete] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [openEditForm, setOpenEditForm] = React.useState(false);
    const [openProgress, setOpenProgress] = React.useState(false);
    const [isSort, setIsSort] = React.useState(false);
    const [formUserDet, setFormUserDet] = React.useState({
        fullname: "",
        email: "",
        iec_no: "",
        mobile: ""
    })

    const handleProgressOpen = () => {
        setOpenProgress(true);
    };

    const handleProgressClose = () => {
        setOpenProgress(false);
    };


    React.useEffect(() => {
        let mounted = true;
        const token = localStorage.getItem('token');
        let skip = 0

        const role = localStorage.getItem('role');
        if (role === "superadmin"){
            
        } else if(role === "admin"){

        } else if (role === "user"){
            navigate("/");
        } else{
            navigate("/")
        }
        handleProgressOpen();
        getUsers(token, skip).then(response => {
            if(mounted){
                // console.log(response)
                if (response){
                    handleProgressClose();
                    if (response.data.data){
                        const totalRecords = response.data.data.count;
                        let pagesToShow = null;
                        if (totalRecords % 10 === 0){
                            pagesToShow = Math.floor(totalRecords / 10);
                        } else{
                            pagesToShow = Math.floor(totalRecords / 10) + 1;
                        }
                        setPageCount(pagesToShow)
                        setUsersList(response.data.data.users);
                    }  
                }
                else{
                    handleProgressClose();
                }
            }
        }, error => {
            handleProgressClose();
            // console.log("getUsers error response: ", error.response)
            if (error.response){
                if (error.response.status === 403){
                    localStorage.clear();
                    window.location.href = "/"
                }
            }
        })
        return () => mounted = false;
    }, [])

    
    const handleEditOpen = (e) => {
        const userDetailsString = e.target.value
        const arr = userDetailsString.split(";")
        const userDetails = {
            fullname: arr[0],
            email: arr[1],
            mobile: arr[2],
            iec_no: arr[3]
        }
        // console.log(userDetails);
        setFormUserDet(userDetails)
        setOpenEditForm(true)
    }
    const handleEditClose = (flag) => {
        if (flag){
            if (formUserDet.fullname.length > 0 && formUserDet.iec_no.length > 0){
                // console.log("update user details: ", formUserDet);
                const token = localStorage.getItem('token');
                updateUser(token, formUserDet).then(response => {
                    
                    if (response){
                        // console.log(response)
                        if(response.data){
                            setOpenEditForm(false)
                            window.location.reload();
                        }  
                    }
                }, error => {
                    console.log("error response: ", error.response)
                    if (error.response){
                        if (error.response.status === 403){
                            localStorage.clear();
                            window.location.href = "/"
                        }
                    }
                    
                }) 
            } else{
                alert("Please fill required details")
            } 
        } else{
            // console.log("cancel");
            setOpenEditForm(false)
        }
    }

    const handleCheckbox = (e) => {
        const val = e.target.value
        if (e.target.checked){
            setToDelete(prevValue => {
                return [...prevValue, val]
            })
        } 
        else{
            setToDelete(toDelete.filter(item => {
                return item !== val
            }))
        }
    }
    const handleClose = (permissionToDel) => {
        if(permissionToDel){
            var deleteUserSchema = {
                "users": []
            };
    
            for (var i = 0; i < toDelete.length; i++) {
    
                const del_user = toDelete[i]
                const [email, iec_no, mobile] = del_user.split(";");
                const user = {
                    email: email,
                    iec_no: iec_no,
                    mobile: mobile
                }
                deleteUserSchema.users.push(user);
            }

            // console.log(deleteUserSchema)
            const token = localStorage.getItem('token');
            
            deleteUser(token, deleteUserSchema).then(response => {
                if (response){
                    window.location.reload();
                    // console.log(response);
                }
            }, error => {
                console.log("error response: ", error.response)
                if (error.response){
                    if (error.response.status === 403){
                        localStorage.clear();
                        window.location.href = "/"
                    }
                }
                
            })
            
        } 
        else{
            // console.log("Don't delete. wait!")
        }
        setOpen(false);
    };

    const removeUser = () => {
        setOpen(true)
    }

    const handlePageChange = (e, val) => {
        const token = localStorage.getItem('token');
        // console.log(val);
        setPage(val);
        const skip = (val * 10) - 10;
        // console.log(skip);
        getUsers(token, skip).then(response => {
            // console.log("resp: ", response.headers);
            if (response){
                if (response.data){
                    setUsersList(response.data.data.users);
                }
            }
        }, error => {
            console.log("error response: ", error.response)
            if (error.response){
                if (error.response.status === 403){
                    localStorage.clear();
                    window.location.href = "/"
                }
            }
            
        })
    }

    // sort by fullname
    const sortByName = () => {
        setIsSort(prev => {
            return !prev
        });
        const copy = [...usersList]
        if (isSort){
            copy.sort((a, b) => {
                return a.fullname.localeCompare(b.fullname)
            })
        } else{
            copy.sort((a, b) => {
                return b.fullname.localeCompare(a.fullname)
            })
        }
        
        setUsersList(copy);
    }

    // sort by IEC No.
    const sortByIEC = () => {

        setIsSort(prev => {
            return !prev
        });
        const copy = [...usersList]
        if (isSort){
            copy.sort((a, b) => {
                return (Number)(a.iec_no) - (Number)(b.iec_no)
            })
        } else{
            copy.sort((a, b) => {
                return (Number)(b.iec_no) - (Number)(a.iec_no)
            })
        }
        
        setUsersList(copy);
    }

    return (
        <div>
            
            {/* <TableContainer style={{minHeight: "86vh"}} component={Paper}> */}
                <TableContainer style={{borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"}} component={Paper}>
                    <Table sx={{ minWidth: 440 }} aria-label="simple table">
                        <TableHead>
                            <TableRow >
                                <TableCell>
                                    <h4 className="outlet-heading">{getRole() === "superadmin" ? "Admins" : "Users"}</h4>
                                    
                                    {/* <button className="search-btn"><i className="fa-solid fa-magnifying-glass"></i></button> */}
                                    <div className="bills-search">
                                        {/* <input name="user" placeholder='Name' className="be-input search-inp" type="text"></input> */}
                                    </div>
                                </TableCell>
                                
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
                
                <TableContainer style={{borderTopLeftRadius: "0px", borderTopRightRadius: "0px"}} component={Paper}>
                    <div id="uploadBar" className={getRole() === "admin" ? "upload-bar remv-upload-bar": "upload-bar"}>
                        
                        <button aria-controls={open ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true" aria-expanded={open ? 'true' : undefined} 
                                onClick={removeUser} title="Select some files" 
                                className="delete-btn" disabled={toDelete.length > 0 ? false : true}>
                                <i className="fa-solid fa-user-minus"></i> {getRole() === "superadmin" ? "remove admin" : "remove user"}
                        </button>
                        <ResponsiveDialog open={open} type="USER" handleClose={handleClose}/>
                    </div>
                    <Table sx={{ minWidth: 400 }} aria-label="simple table">

                        <TableHead>

                            <TableRow style={{backgroundColor: "#f8f9fa"}}>
                                <TableCell id="remv-headings" align="left"><h6><CheckBoxIcon style={{ width: "18px" }} /></h6></TableCell>
                                <TableCell><h6>No</h6></TableCell>
                                <TableCell><h6>Full Name <i onClick={sortByName} title="Sort by Name" className="fa-solid fa-sort sort-btn"></i></h6></TableCell>
                                <TableCell align="left"><h6>Email Id</h6></TableCell>
                                <TableCell align="left"><h6>Mobile</h6></TableCell>
                                { getRole() === "superadmin" ? 
                                <TableCell align="left"><h6>IEC No. <i onClick={sortByIEC} title="Sort by IEC no." className="fa-solid fa-sort sort-btn"></i></h6></TableCell>
                                : null}
                                
                                <TableCell id="remv-headings" align="right"><h6>Creation date</h6></TableCell>
                                <TableCell align="right"><h6>Options</h6></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {usersList ? usersList.map((row, index, arr) => (
                                
                                <TableRow key={index + 1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} > 

                                    <TableCell id="remv-headings" align="left"><input onChange={handleCheckbox} value={[row.email + ";" + row.iec_no  + ";" + row.mobile]} style={{ width: "20px" }} type="checkbox"></input> </TableCell>
                                    <TableCell align="left">{index + 1 + ((page * 10) - 10)}</TableCell>
                                    <TableCell>
                                        {row.fullname}
                                    </TableCell>
                                    <TableCell style={{fontWeight: "500"}} align="left">{row.email}</TableCell>
                                    <TableCell style={{ color: "#333C83", fontSize: "16px" }} align="left">{row.mobile}</TableCell>
                                    { getRole() === "superadmin" ? 
                                    <TableCell align="left">{row.iec_no}</TableCell>
                                    : null}
                                    
                                    <TableCell id="remv-headings" align="right">{String(String(new Date(row.created_at))).split("GMT")[0].slice(3)}</TableCell>
                                    <TableCell align="right">
                                        <Button style={{backgroundColor: "#256D85"}}
                                            value={[row.fullname + ";" + row.email + ";" + row.mobile + ";" + row.iec_no]}
                                            id="demo-customized-button"
                                            aria-controls={openEditForm ? 'demo-customized-menu' : undefined}
                                            aria-haspopup="true"
                                            size="small"
                                            aria-expanded={openEditForm ? 'true' : undefined}
                                            variant="contained"
                                            onClick={handleEditOpen}
                                            disableElevation
                                        >
                                            EDIT
                                        </Button>
                                        
                                    </TableCell>
                                </TableRow>
                            )) : null}
                            
                        </TableBody>
                    </Table>
                    <FormDialog changeUpdates={setFormUserDet} currUser={formUserDet} openEditForm={openEditForm} handleEditClose={handleEditClose}/>
                </TableContainer>
                <Box sx={{ width: '100%' }} style={openProgress ? {display: "block"} : {display: "none"}}>
                    <LinearProgress />
                </Box>
                <div className="pagination-stack">
                    <Stack spacing={2}>
                        <Pagination count={pageCount} page={page} shape="rounded" onChange={handlePageChange}/>
                    </Stack>
                </div>
            {/* </TableContainer> */}
            
        </div>
    );
}
