export const getRole = () => {
    const role = localStorage.getItem('role');
    if (role){
        return role;
    } else {
        return null;
    }
    
}

export const getToken = () => {
    const token = localStorage.getItem('token');
    if (token){
        return token;
    } else {
        return null;
    }
}