import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getRole } from './util';
import {createUser} from "../services/Service";
import { BasicAlert } from './Error';
import { useNavigate } from 'react-router-dom'

const theme = createTheme();


export function UserCreated(props){

    // Get name of the logged in user stored in local storage
    const name = localStorage.getItem('name');

    return (
        <div>
            
            <Container component="main" maxWidth="sm" sx={{ mb: 4, pt: 4 }}>
                
                <h4>{props.type === "create" ? "Account created successfully": "Account password reset"}</h4>
                <p>Please share the below details.</p>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Typography component="h1" variant="h5" align="center">
                        <strong>{props.fullname}</strong>
                    </Typography>

                    <React.Fragment>
                        
                        <Grid style={{marginTop: "15px"}} container spacing={3}>
                            
                            <Grid align="left" item xs={12}>
                                <h6>Email Address</h6>
                                <TextField
                                    aria-readonly={false}
                                    value={props.email}
                                    id="address2"
                                    name="address2"
                                    
                                    fullWidth
                                    autoComplete="shipping address-line2"
                                    variant="standard"
                                />
                            </Grid>
                            
                            
                            <Grid align="left" item xs={12} sm={6}>
                                <h6>Password</h6>
                                <TextField
                                    value={props.password}
                                    required
                                    id="zip"
                                    name="zip"
                                    fullWidth
                                    autoComplete="shipping postal-code"
                                    variant="standard"
                                />
                            </Grid>
                            <Grid style={{alignItems: "center", padding: "54px 0"}} align="right" item xs={12} sm={6}>
                                <a target="_blank" href={"mailto:" + props.email + "?subject=Lucrative account login details&body=Hi " + props.fullname + ",%0D%0A%0D%0APlease find the below login details for Lucrative. Change the password after login with the below given password.%0D%0ALogin at: https://www.lucrative.dashboard.com  %0D%0AEmail:- " + props.email + "%0D%0APassword:- " + props.password + "%0D%0A%0D%0ARegards,%0D%0A" + name + "%0D%0A%0D%0A"}>share</a>
                                
                            </Grid> 
                            
                        </Grid>
                    </React.Fragment>
                    
                </Paper>
                <a href="/lucrative/users">close</a>
            </Container>
        </div>
    )
}

export function CreateUser() {


    let navigate = useNavigate()
    const [showWarning, setShowWarning] = React.useState({display: "none"});
    const [emailValidationMsg, setEmailValidationMsg] = React.useState();
    const [isUserCreated, setIsUserCreated] = React.useState(false);
    const [responseData, setResponseData] = React.useState({});


    React.useEffect(() => {
        const role = localStorage.getItem('role');
        if (role === "superadmin"){
            
        } else if(role === "admin"){

        } else if (role === "user"){
            navigate("/");
        } else{
            navigate("/")
        }
    }, [])

    // Email validation
    function emailValidation(email) {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!email || regex.test(email) === false) {
            setEmailValidationMsg("Email is not valid");
            setShowWarning({display: "inline-block"});
            setTimeout(() => {
                setShowWarning({display: "none"});
            }, 6000)
            return false;
        }
        return true;
    }

    // Mobile no. validation
    function phonenumber(inputtxt)
    {
        var phoneno = /^\d{10}$/;
        if(phoneno.test(inputtxt)){
            return true;
        }
        else {
            setEmailValidationMsg("Mobile no. is not valid");
            setShowWarning({display: "inline-block"});
            setTimeout(() => {
                setShowWarning({display: "none"});
            }, 6000)
            return false;
        }
    }

    // Handle and submit created user details
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let mobile = data.get('mobile');
        let iec = data.get('iec');
        let isSubmit = true

        // if mobile no input box is not filled, then don't submit request
        if (mobile.length <= 0) {
            // mobile = null;
            isSubmit = false;
            setEmailValidationMsg("Please fill required details");
            setShowWarning({display: "inline-block"});
            setTimeout(() => {
                setShowWarning({display: "none"});
            }, 6000)
        } else{
            const isMobile = phonenumber(mobile);
            if(!isMobile){
                return null;
            }
        }
        
        // New user details
        const create_data = {
            fullname: data.get('firstName') + " " + data.get('lastName'),
            email: data.get('email'),
            iec_no: data.get('iec'),
            mobile: mobile
        };

        // console.log(create_data)

        // User details validation
        if (data.get('firstName').length > 0 && data.get('lastName').length > 0) {
            
            const isValid = emailValidation(create_data.email);
            if (isValid) {
                if (iec !== null) {
                    if (iec.length <= 0) { 
                        isSubmit = false;
                        setEmailValidationMsg("Please fill required details");
                        setShowWarning({display: "inline-block"});
                        setTimeout(() => {
                            setShowWarning({display: "none"});
                        }, 6000)
                    }
                }
                if (isSubmit) {
                    const token = localStorage.getItem('token');

                    // API call
                    createUser(token, create_data).then(response => {
                        // console.log(response);
                        if (response){
                            if (response.data){
                                if (response.data.msg === "Unauthorized request"){
                                    window.location.reload();
                                }

                                if(response.data.msg === "User already exists"){
                                    setEmailValidationMsg(response.data.msg);
                                    setShowWarning({display: "inline-block"});
                                    setTimeout(() => {
                                        setShowWarning({display: "none"});
                                    }, 6000)

                                    return;
                                }
                                setIsUserCreated(true);
                                setResponseData(response.data.data);
                                setEmailValidationMsg("Success");
                                
                            } else{
                                setEmailValidationMsg(response.data.msg);
                                setShowWarning({display: "inline-block"});
                                setTimeout(() => {
                                    setShowWarning({display: "none"});
                                }, 6000)
                            }
                        }
                    }, error => {
                        console.log("error response: ", error.response)
                        if (error.response){
                            if (error.response.status === 403){
                                localStorage.clear();
                                window.location.href = "/"
                            }
                        }
                        
                    })
                }
            }

        } else {
            setEmailValidationMsg("Please fill required details");
            setShowWarning({display: "inline-block"});
            setTimeout(() => {
                setShowWarning({display: "none"});
            }, 6000)
        }
    };

    return (
        <div>
            <TableContainer style={{ height: "86vh" }} component={Paper}>
                <TableContainer style={{ borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px" }} component={Paper}>
                    <Table sx={{ minWidth: 300 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                            <TableCell><h4 className='outlet-heading'>{getRole() === "superadmin" ? "Create Admin" : "Create User"}</h4></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>

                <TableContainer>
                <ThemeProvider theme={theme}>
                    
                    <Container  component="main" maxWidth="xs">
                        {/* <CssBaseline /> */}
                        <Box style={isUserCreated ? {display: "none"} : {display: "block"}}
                            sx={{
                                marginTop: 3,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar style={{margin: "7% auto", backgroundColor: "#256D85"}} sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LockOutlinedIcon />
                                
                            </Avatar>
                            
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
                            
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"
                                            required
                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="lastName"
                                            label="Last Name"
                                            name="lastName"
                                            autoComplete="family-name"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                        />
                                    </Grid>
                                    {getRole() === "superadmin" ? <Grid item xs={12}>
                                        <TextField
                                            autoComplete="iec"
                                            name="iec"
                                            required
                                            fullWidth
                                            id="iec"
                                            label="IEC"

                                        />
                                    </Grid> : null}

                                    {/* {getRole() === "superadmin" ? null : <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="br"
                                            label="Br"
                                            name="br"
                                            autoComplete="br"
                                        />
                                    </Grid>} */}

                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="mobile"
                                            label="Mobile No."
                                            type="text"
                                            id="mobile"
                                            autoComplete="mobile"
                                        />
                                    </Grid>

                                </Grid>
                                <BasicAlert style={showWarning} message={emailValidationMsg} />
                                <Button style={{backgroundColor: "#256D85"}}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    {getRole() === "superadmin" ? "CREATE ADMIN" : "CREATE USER"}
                                </Button>

                            </Box>
                        </Box>
                        {isUserCreated ? <UserCreated type="create" email={responseData.email} fullname={responseData.fullname} password={responseData.password} /> : null}
                    </Container>
                </ThemeProvider>
                </TableContainer>

            </TableContainer>

        </div >
    )
}