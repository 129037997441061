import * as React from "react";
import Navbar from "./Navbar";
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Footer from './Footer'
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import {loginService} from "../services/Service";
import {BasicAlert} from "./Error";

// function Copyright(props) {
//     return (
//         <Typography variant="body2" color="text.secondary" align="center" {...props}>
//             {'Copyright © '}
//             <Link target="__blank__" color="inherit" href="#">
//               Nobilis Infotech Pvt. Ltd.
//             </Link>{' '}
//             {new Date().getFullYear()}
//             {'.'}
//         </Typography>
//     );
// }

const theme = createTheme();

function Login(props) {
    let navigate = useNavigate();


    React.useEffect(() => {
        const token = localStorage.getItem('token');
        if (token){
            if (token.length > 0){
                navigate("/lucrative");
            }
        }
    }, []);

    const [emailValidate, setEmailValidation] = React.useState("");
    const [showWarning, setShowWarning] = React.useState({display: "none"});
    

    function emailValidation(email){
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if(!email || regex.test(email) === false){
            setShowWarning({display: "inline-block"});
            setTimeout(() => {
                setShowWarning({display: "none"});
            }, 6000)
            setEmailValidation("Email is not valid");
            return false;
        }
        return true;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email')
        const password = data.get('password')
        
        if (email.length > 0 && password.length > 0){
            const res = emailValidation(email);
            if (res){
                const userDetails = {
                    email: email,
                    password: password
                }
                
                loginService(userDetails).then(response => {
                    if(response){
                        if (response.data){
                            // console.log(response.data);
                            setEmailValidation(response.data.msg);
                            setShowWarning({display: "inline-block"});
                            setTimeout(() => {
                                setShowWarning({display: "none"});
                            }, 6000)
                            localStorage.setItem('token', response.data.data.token);
                            localStorage.setItem('role', response.data.data.role);
                            localStorage.setItem('name', response.data.data.name);
                            
                            navigate('/lucrative');
                        }   
                    }
                }, error => {
                    // console.log(error.response)
                    if(error.response){
                        setEmailValidation(error.response.data.msg);
                        setShowWarning({display: "inline-block"});
                        setTimeout(() => {
                            setShowWarning({display: "none"});
                        }, 6000)
                    }
                });
            }
        } else{
            setEmailValidation("Please enter details");
            setShowWarning({display: "inline-block"});
            setTimeout(() => {
                setShowWarning({display: "none"});
            }, 6000)
        }
    };

    return (
        <div>
            <Navbar type={props.navtype} bgColor="#0e4263" />
            {/* <Navbar type={props.navtype} bgColor="#06283D" /> */}
            <div>
            <TableContainer className="home-branding-bg" component={Paper}>
                       
                {/* <p>Hello</p> */}
            
            <ThemeProvider style={{backgroundColor: "transparent"}} theme={theme}>
                
                <Container style={{backgroundColor: "transparent", marginTop: "10vh", zIndex: 2}} component="main" maxWidth="xs">
                    <CssBaseline />
                    <Card>
                        {/* <Card style={{borderBottomRightRadius: 0, borderBottomLeftRadius: 0, backgroundColor: "#0e4263", color: "#cccccc"}}>
                            <Typography style={{textAlign: "center", padding: "3px"}} component="h1" variant="h6">
                                Lucrative Exim Outsourcing Pvt. Ltd.
                            </Typography>
                        </Card> */}
                        <Box  style={{backgroundColor: "transparent"}}
                        sx={{
                            
                            marginTop: 3,
                            marginBottom: 1,
                            marginLeft: 3,
                            marginRight: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar style={{backgroundColor: "#256D85"}} sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, mb : 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                
                            />
                            <BasicAlert style={showWarning} message={emailValidate} />
                            
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            />
                            
                            {/* <Button style={{backgroundColor: "#f8c249"}} */}
                            <Button style={{backgroundColor: "#256D85"}}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 3 }}
                            >
                                Sign In
                            </Button>
                            <Typography color="text.secondary" style={{textAlign: "center"}}> Lucrative Exim Outsourcing Pvt. Ltd. </Typography>
                            <Grid container>
                                
                            </Grid>
                        </Box>
                    </Box>
                    </Card>
                    
                    
                </Container>
                
                
            </ThemeProvider>
                    {/* <Card className="mt-4 mr-1 " style={{height: "78vh"}}>
                        <h2>GHello</h2>
                    </Card> */}
            
            </TableContainer>
            </div>
            <Footer />
            
        </div>);
}

export default Login