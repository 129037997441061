import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function Develop() {
    return (
        
        <Typography variant="body2" color="white" align="center">
            {' Developed By '}
            <Link target="__blank__" color="inherit" href="#">
                Nobilis Infotech Pvt. Ltd. {' '}
            </Link>
            {new Date().getFullYear()}
            {'.'}
        </Typography>
 
    );
}

function Copyright() {
    return (
        
        <Typography className="pb-1" variant="body2" color="white" align="center">
            {' Copyright © '}
            <Link target="__blank__" color="inherit" href="#">
                LEO TECH EXIM SOLUTIONS LLP, INDIA {' '}
            </Link>
            
        </Typography>
 
    );
}

export default function Dashboard(){
    return (
        <div className="footer" style={{ padding: "4px"}}>
            
            <TableContainer style={{padding: "3px 0" ,backgroundColor: "rgb(14, 66, 99)"}} component={Paper}>    
                <Copyright />
                <Develop />        
            </TableContainer>
        </div>
    )
}