import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';

export default function Navbar(props) {

  const navigate = useNavigate();

  const handleMenu = (event) => {
    navigate("account")
  };


  return (
    <Box sx={{ flexGrow: 1 }}>
      
      <AppBar style={props.bgColor ? {backgroundColor: props.bgColor} : null} position="static">

        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            // sx={{ mr: 1 }}
          >
            {/* <MenuIcon /> */}
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <div style={{marginLeft: "1%"}}>
            
              <img width="92" height="32" src="/images/d2d_logo2.png"></img>
              <span className="mt-1" style={{float: "right", fontWeight: 400, fontSize: "18px"}}>
                doc2data.in
              </span>
            </div> 
          </Typography>
          {props.type !== "signin" && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
             
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
