import axios from "axios";
import {SERVER_URL} from "./server"

// API calls
// Login 
export async function loginService(userDetails){
    
    return await axios.post(SERVER_URL + "/login", userDetails);
    
}


// Create new user
export async function createUser(token, newUserDetails){
    
    // JWT Bearer token authentication = { headers : {Authorization : 'Bearer ' + token}}
    return await axios.post(SERVER_URL + "/create-user", newUserDetails, {headers : {'Authorization': 'Bearer ' + token}});
    
}


// List users
export async function getUsers(token, skip){
    
    return axios.get(SERVER_URL + "/users?skip=" + skip, {headers : {'Authorization': 'Bearer ' + token}})
    
}

// Download bills
export async function downloadBill(token, downloadBillSchema){
    
    return await axios({
        url: SERVER_URL + "/list-bills/download",
        method: 'POST',
        responseType: 'blob', // important
        data: downloadBillSchema,
        headers: {'Authorization': 'Bearer ' + token}
    });
}

export async function downloadBillsWithLimit(token, skip, limit, billType){
    return await axios({
        url: SERVER_URL + "/list-bills/download-all?billType=" + billType + "&skip=" + skip + "&limit=" + limit,
        method: 'GET',
        responseType: 'blob', // important
        headers: {'Authorization': 'Bearer ' + token}
    });
}

// Download bills
export async function downloadShippingBill(token, downloadBillSchema){
  
    return await axios({
        url: SERVER_URL + "/list-shipping-bills/download",
        method: 'POST',
        responseType: 'blob', // important
        data: downloadBillSchema,
        headers: {'Authorization': 'Bearer ' + token}
    });
    
}

// Download bills
export async function downloadCourierBill(token, downloadBillSchema){
  
    return await axios({
        url: SERVER_URL + "/list-courier-bills/download",
        method: 'POST',
        responseType: 'blob', // important
        data: downloadBillSchema,
        headers: {'Authorization': 'Bearer ' + token}
    });
    
}

// Get logged in user details
export async function userAccount(token){
    return await axios.get(SERVER_URL + "/user-account", {headers : {'Authorization': 'Bearer ' + token}});
}


// Get user role
export async function userRole(token){
    
    return await axios.get(SERVER_URL + "/user-role", {headers : {'Authorization': 'Bearer ' + token}});
    
}


// Get pending BOE files
export async function getPendingFiles(token, billType){
    
    return await axios.get(SERVER_URL + "/pending-file-status?billType=" + billType, {headers : {'Authorization': 'Bearer ' + token}});
    
}


// Change password
export async function changePassword(token, changePassword){
    return await axios.post(SERVER_URL + "/users/change-password", {password: changePassword} ,{headers : {'Authorization': 'Bearer ' + token}});
    
}


// Update user details
export async function updateUser(token, updateUserDetails){
    return await axios.put(SERVER_URL + "/update-user", updateUserDetails, {headers : {'Authorization': 'Bearer ' + token}});
    
}


// Reset user password
export async function resetPassword(token, updateUserDetails){
    return await axios.put(SERVER_URL + "/reset-password", updateUserDetails, {headers : {'Authorization': 'Bearer ' + token}});
    
}


// Upload pdf files
export async function upload(token, upload_file, billType){
    console.log("Print upload file", upload_file);
    var formData = new FormData();

    for (var i = 0; i < upload_file.length; i++){
        formData.append(
            "upload_file",
            upload_file[i]
        );
    }
    
    console.log(formData.keys);
    // const headers = {'Content-Type': 'multipart/form-data'}
    
    return await axios.post(SERVER_URL + "/upload?billType=" + billType, formData, { headers : {'Authorization': 'Bearer ' + token, 'Content-Type': 'multipart/form-data'}});
    
}


// Delete user
export async function deleteUser(token, delete_user_schema){
    return await axios.delete(SERVER_URL + "/delete-user", {headers: { 'Authorization': 'Bearer ' + token}, data: delete_user_schema});
    
}


// Search BOEs with daterange, be no, iec
export async function searchBillsAPICall(token, searchBillDetails, skip){
    return await axios.post(SERVER_URL + "/search-bills?skip=" + skip, searchBillDetails, {headers : {'Authorization': 'Bearer ' + token, 'accept' : 'application/json','Content-Type' : 'application/json'}});
    
}

// Search Shipping Bills with daterange, be no, iec
export async function searchShippingBillsAPICall(token, searchBillDetails, skip){
    return await axios.post(SERVER_URL + "/search-shipping-bills?skip=" + skip, searchBillDetails, {headers : {'Authorization': 'Bearer ' + token, 'accept' : 'application/json','Content-Type' : 'application/json'}});
    
}

// Search Shipping Bills with daterange, be no, iec
export async function searchCourierBillsAPICall(token, searchBillDetails, skip){
    return await axios.post(SERVER_URL + "/search-courier-bills?skip=" + skip, searchBillDetails, {headers : {'Authorization': 'Bearer ' + token, 'accept' : 'application/json','Content-Type' : 'application/json'}});
    
}

// Delete bills
export async function deleteBillAPICall(token, deleteBillSchema){
    return await axios.delete(SERVER_URL + "/delete-bill", {headers : {'Authorization': 'Bearer ' + token}, data: deleteBillSchema});

}

// Delete All bills
export async function deleteAllBillsAPICall(token, truncatePassword, billType){
    return await axios.delete(SERVER_URL + "/bill/delete-all?billType=" + billType, {headers : {'Authorization': 'Bearer ' + token}, data: truncatePassword});

}

// Delete All bills for an IEC No.
export async function deleteAllBillsForUserIECNoAPICall(token, billType){
    return await axios.delete(SERVER_URL + "/bill/delete-iec?billType=" + billType, {headers : {'Authorization': 'Bearer ' + token}});

}

// Delete Shipping bills
export async function deleteShippingBillAPICall(token, deleteBillSchema){
    return await axios.delete(SERVER_URL + "/delete-shipping-bill", {headers : {'Authorization': 'Bearer ' + token}, data: deleteBillSchema});

}

// Delete Courier bills
export async function deleteCourierBillAPICall(token, deleteBillSchema){
    return await axios.delete(SERVER_URL + "/delete-courier-bill", {headers : {'Authorization': 'Bearer ' + token}, data: deleteBillSchema});

}

