import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { getRole } from './util';
import { ChangePasswordDialog } from './Dialog';
import { userAccount } from '../services/Service';

export default function Account() {

    const [user, setUser] = React.useState(null);
    const [openEditForm, setOpenEditForm] = React.useState(false);
    React.useEffect(()=> {
        
        const token = localStorage.getItem('token');
        userAccount(token).then(response => {
            if (response){
                if (response.data){
                    setUser(response.data.data);
                }
            }
        }, error => {
            console.log("error response: ", error.response)
            if (error.response){
                if (error.response.status === 403){
                    localStorage.clear();
                    window.location.href = "/"
                }
            }
            
        })
    }, [])


    const handleEditOpen = (e) => {
        setOpenEditForm(true)
    }

    const handleEditClose = () => {
        
        setOpenEditForm(false)
        
    }
    return (
        <div>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><h4 className='outlet-heading'>Account</h4></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                </Table>

            </TableContainer>

            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                {(user) ? 
                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Typography component="h1" variant="h5" align="center">
                        <strong>{user.fullname}</strong>
                    </Typography>
                    
                        <React.Fragment>
                            
                            <Grid style={{marginTop: "15px"}} container spacing={3}>
                                
                                <Grid align="left" item xs={12}>
                                    <h6>Email Address</h6>
                                    <TextField
                                        aria-readonly={false}
                                        value={user.email}
                                        id="email"
                                        name="emailaddress"
                                        
                                        fullWidth
                                        autoComplete="email address"
                                        variant="standard"
                                    />
                                </Grid>
                                
                                { getRole() === "superadmin" ? null : <Grid container spacing={3} item xs={12}>
                                    <Grid align="left" item xs={12} sm={getRole() === "admin" ? 12: 6}>
                                        <h6>IEC</h6>
                                        <TextField
                                            required
                                            value={user.iec_no}
                                            id="iec"
                                            name="iec"
                                            fullWidth
                                            autoComplete="iec no"
                                            variant="standard"
                                        />
                                    </Grid>
                                    {getRole() === "admin" ? null : <Grid align="left" item xs={12} sm={6}>
                                        <h6>Br</h6>
                                        <TextField
                                        value={user.br}
                                            id="br"
                                            name="br"
                                            fullWidth
                                            variant="standard"
                                        />
                                    </Grid>}
                                    
                                    </Grid>}
                                
                                <Grid align="left" item xs={12}>
                                    <h6>Mobile</h6>
                                    <TextField
                                        style={{cursor: "none"}}
                                        value={user.mobile}
                                        id="mobile"
                                        name="mobile"
                                        
                                        fullWidth
                                        autoComplete="mobile number"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid align="left" item xs={12} sm={6}>
                                    <h6>Password</h6>
                                    <TextField
                                        value={"*************"}
                                        required
                                        id="password"
                                        name="password"
                                        fullWidth
                                        autoComplete="password"
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid style={{alignItems: "center", padding: "52px 0"}} align="right" item xs={12} sm={6}>
                                    <button className="upload-btn" onClick={handleEditOpen}>Change Password</button>
                                    
                                </Grid> 
                            </Grid>
                        </React.Fragment>
                </Paper> : null}
                
                <ChangePasswordDialog  openEditForm={openEditForm} handleEditclose={handleEditClose}/>
            </Container>
            
        </div>
    )
}