import * as React from 'react';
import Navbar from './Navbar';
import { useLocation } from 'react-router-dom';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import {Link, Outlet} from 'react-router-dom';
import { getRole, getToken } from './util';
import Footer from './Footer'
import {Error} from './Error';
import { userRole } from '../services/Service';

const logOut = () => {
    // Clear session storage
    localStorage.clear();
}

// home page
function Home(){
    
    const [role, setRole] = React.useState(null);
    const [isActive, setIsActive] = React.useState({
        "dashboard": true,
        "create_user": false,
        "users": false,
        "import_bills": false,
        "export_bills": false,
        "courier_bills":false,
        "account": false,
        "logout": false,
    })

    const location = useLocation();
    const pathname = String(location.pathname);
    // console.log(pathname);

    React.useEffect(() => {

        if (pathname === "/lucrative"){
            setIsActive(prev => {
                return {
                    "dashboard": true
                }
            })
        } 
        else if(pathname === "/lucrative/import"){
            setIsActive(prev => {
                return {
                    "import_bills": true
                }
            })
        }
        else if(pathname === "/lucrative/export"){
            setIsActive(prev => {
                return {
                    "export_bills": true
                }
            })
        }
        else if(pathname === "/lucrative/courier"){
            setIsActive(prev => {
                return {
                    "courier_bills": true
                }
            })
        }
        else if(pathname === "/lucrative/users"){
            setIsActive(prev => {
                return {
                    "users": true
                }
            })
        }
        else if(pathname === "/lucrative/create"){
            setIsActive(prev => {
                return {
                    "create_user": true
                }
            })
        }
        else if(pathname === "/lucrative/account"){
            setIsActive(prev => {
                return {
                    "account": true
                }
            })
        }
        
        const token = localStorage.getItem('token');
        // Get user role
        userRole(token).then(response => {
            // console.log(response.data)
            if (response){
                if (response.data){
                    const role = response.data.data
                    setRole(role)
                    localStorage.setItem('role', role);
                }
            }
        }).catch( error => {
            console.log("error response: ", error.response)
            if (error.response){
                if (error.response.status === 403){
                    localStorage.clear();
                    window.location.href = "/"
                }
            }  
        });
    }, [])

    const activeLink = e => {
        var linkName = e.currentTarget.id;
       

        if (linkName === "dashboard"){
            setIsActive(prev => {
                return {
                    "dashboard": true
                }
            });  
        } else if(linkName === "create_user"){
            setIsActive(prev => {
                return {
                    "create_user": true
                }
            });
        }
        else if(linkName === "users"){
            setIsActive(prev => {
                return {
                    "users": true
                }
            });
        }
        else if(linkName === "import_bills"){
            setIsActive(prev => {
                return {
                    "import_bills": true
                }
            });
        }
        else if(linkName === "export_bills"){
            setIsActive(prev => {
                return {
                    "export_bills": true
                }
            })
        }
        else if(linkName === "courier_bills"){
            setIsActive(prev => {
                return {
                    "courier_bills": true
                }
            })
        }
        else if(linkName === "account"){
            setIsActive(prev => {
                return {
                    "account": true
                }
            });
        }
 
    }

    return (
    <div>
        {role !== null && getToken() !== null ? <div> 
            <Navbar bgColor="#0e4263"/>
            <div className="left-bar-container">
                <TableContainer style={{minHeight: "86vh"}} component={Paper}>
                    <ul>
                        <li key="1" ><Link onClick={activeLink} id="dashboard" className={isActive.dashboard ? "active-link" : "links"}  to="" ><DashboardIcon id="nav-link-icons" /> <span className="nav-link-txt">Dashboard</span></Link></li>
                        { getRole() === "superadmin" || getRole() === "admin" ? <span>
                            <li key="2"><Link onClick={activeLink} id="create_user" className={isActive.create_user ? "active-link" : "links"} to="create" ><AddBoxRoundedIcon id="nav-link-icons" /> <span className="nav-link-txt">{getRole() === "superadmin" ? "Create Admin" : "Create User"}</span></Link></li>
                            <li key="3"><Link onClick={activeLink} id="users" className={isActive.users ? "active-link" : "links"} to="users" ><PeopleAltRoundedIcon id="nav-link-icons" /> <span className="nav-link-txt">{getRole() === "superadmin" ? "Admins" : "Users"}</span></Link></li>
                        </span> : null }
                        <li key="4"><Link onClick={activeLink} id="import_bills" className={isActive.import_bills ? "active-link" : "links"} to="import" ><FileCopyRoundedIcon id="nav-link-icons" /> <span className="nav-link-txt">Bill of Entry (Import)</span></Link></li>
                        <li key="5"><Link onClick={activeLink} id="export_bills" className={isActive.export_bills ? "active-link" : "links"} to="export" ><FileCopyRoundedIcon id="nav-link-icons" /> <span className="nav-link-txt">Shipping Bills (Export)</span></Link></li>
                        <li key="8"><Link onClick={activeLink} id="courier_bills" className={isActive.courier_bills ? "active-link" : "links"} to="courier" ><FileCopyRoundedIcon id="nav-link-icons" /> <span className="nav-link-txt">Courier Bill of Summary</span></Link></li>

                        <li key="6"><Link onClick={activeLink} id="account" className={isActive.account ? "active-link" : "links"} to="account" ><PersonIcon id="nav-link-icons" /> <span className="nav-link-txt">Account</span></Link></li>
                        <li key="7"><Link id="logout" className="links" to="/" onClick={logOut}><LogoutRoundedIcon id="nav-link-icons" /> <span className="nav-link-txt">Log out</span></Link></li>
                    </ul>
                </TableContainer>
            </div>
            
            <div className="right-container">
                <TableContainer style={{height: "86vh"}} component={Paper}>
                    <Outlet />
                </TableContainer>
            </div>
            
            <Footer />
        </div> : null}
       
    </div>
    
    )
}

export default Home