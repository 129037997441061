import * as React from 'react';
import axios from "axios";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import { getPendingFiles } from '../services/Service';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useLocation } from 'react-router-dom';


export default function Dashboard(){

    const [pendingBills, setPendingBills] = React.useState(null);
    const [openProgress, setOpenProgress] = React.useState(false);
    
    const [billType, setBillType] = React.useState('Import');

    const handleChangeBillType = (event) => {
        setBillType(event.target.value);
        getFileStatus(event.target.value);
    };

    const handleBackdropClose = () => {
        setOpenProgress(false);
    };

    const handleToggle = () => {
        setOpenProgress(!openProgress);
    };

    const getFileStatus = (billType) => {
        const token = localStorage.getItem('token');
        setPendingBills(null);
        handleToggle();
        getPendingFiles(token, billType).then(response => {
            // console.log(response.data.data);

            if (response){
                handleBackdropClose();
                if(response.data){
                    if (response.data.data){
                        if (response.data.data.length !== 0)
                        {
                            setPendingBills(response.data.data)
                        }
                    }
                }
            }
        }, error => {
            handleBackdropClose();
            console.log("Error response: ", error.response)
            if (error.response){
                if (error.response.status === 403){
                    localStorage.clear();
                    window.location.href = "/"
                }
            }
        })
    }

    const location = useLocation();
    const pathname = String(location.pathname);

    React.useEffect(() => {
       
        const queryParameter = location.search;
        console.log('queryParameter.substring0',queryParameter,'--',queryParameter.substring.toString())
        if (queryParameter.includes("export")){
            console.log('In Export',queryParameter.substring)
            getFileStatus("Export");
            setBillType("Export");
        } else if(queryParameter.includes("Import")){
            console.log('In Import',queryParameter.substring)
            getFileStatus("Import");
            setBillType("Import");
        }
        else if(queryParameter.includes("courier")){
            console.log('In Courier')
            getFileStatus("Courier");
            setBillType("Courier");
        }
        else {
            console.log('In Import',queryParameter.substring)
            getFileStatus("Import");
            setBillType("Import");
        }


        // console.log("Path: ", pathname)

    }, [])

    const startProcessing = () => {
        console.log("start processing: ");
        axios.get("http://localhost:8000/api/process-files").then(response => {
    
        })
      }

    return (

        <div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                        <TableCell><h4 className="outlet-heading">Dashboard</h4></TableCell>
                            {/* <TableCell></TableCell> */}
                            
                            <TableCell style={{float: "right"}} align="left">
                            {/* <Box sx={{ maxWidth: 200, maxHeight: 50 }}> */}
                                <FormControl sx={{minWidth: 120, height: 38 }} size="small">
                                    <InputLabel  id="demo-select-small">{billType}</InputLabel>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-simple-select"
                                        value={billType}
                                        label="Select"
                                        onChange={handleChangeBillType}
                                    >
                                        <MenuItem value={'Import'}>Import</MenuItem>
                                        <MenuItem value={'Export'}>Export</MenuItem>
                                        <MenuItem value={'Courier'}>Courier</MenuItem>
                                    </Select>
                                </FormControl>
                            {/* </Box> */}

                            </TableCell>
                            {/* <TableCell align="center"><button className="run-btn" onClick={startProcessing}><i className="fa-solid fa-play"></i> Run</button></TableCell> */}
                            
                        </TableRow>
                    </TableHead>
                </Table>
                
            </TableContainer>
            
            <TableContainer style={{margin: "15px 0 0", height: "40px", textAlign: "left"}} component={Paper}>
                <h5 style={{ color: "#525E75", padding: "4px 16px", fontFamily: "Fira Sans, sans-serif" }}>File status { "(" + billType + ")"}</h5>

            </TableContainer>
            
            <TableContainer style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }} component={Paper}>
                <Table sx={{ minWidth: 300 }} aria-label="simple table">

                    <TableHead style={{}}>

                        <TableRow style={{ backgroundColor: "#f8f9fa" }}>
                            
                            <TableCell align="left"><h6>No</h6></TableCell>
                            <TableCell align="left"><h6>File Name</h6></TableCell>                           
                            <TableCell align="left"><h6>Upload Date </h6></TableCell>
                            <TableCell align="left"><h6>Remarks</h6></TableCell>
                            <TableCell align="center"><h6>Status </h6></TableCell>
                             
                            {/*<TableCell id="remv-headings" style={{ paddingRight: "34px" }} align="right"><h6>X</h6></TableCell> */}

                        </TableRow>
                    </TableHead>
                    {pendingBills ? pendingBills.map((row, index, arr) => (
                    <TableBody style={{boxShadow: "1px solid black"}} key={index+1}>
                            <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}> 
                                <TableCell > {index + 1} </TableCell>
                                
                                <TableCell align="left"><i style={{ color: "tomato", width: "18px" }} className="fa-solid fa-file-pdf"></i> {String(row.pdf_filename).split("_")[0] + ".pdf"}</TableCell>
                                <TableCell align="left">{String(new Date(new Date(row.created_at).getTime() + (330*60000))).split("GMT")[0].slice(3)}</TableCell>
                                
                                <TableCell style={{ color: "#333C83", fontSize: "16px" }} align="left">{row.remarks}</TableCell>
                                <TableCell align="center">
                                    <span style={{ backgroundColor: "#FFF6EA", padding: "4px", borderRadius: "4px", fontSize: "16px" }}>
                                        {row.status}
                                    </span>
                                </TableCell>
                            </TableRow>
                    </TableBody>
                    )) : null }

                </Table>
            </TableContainer>    
            {openProgress == true ? null : pendingBills ? null :<div style={{textAlign: "center", color: "grey", margin: "10% 0"}}> <h4>Nothing to process</h4></div>}
            <Box sx={{ width: '100%' }} style={openProgress ? {display: "block"} : {display: "none"}}>
                <LinearProgress />
            </Box>
        </div>
    )
}