import Alert from '@mui/material/Alert';

export function Error(){
    return (
        <div style={{height: "100%", textAlign: "center"}}>
            <Alert style={{height: "100%", textAlign: "center"}} severity="error">
                
                {/* <AlertTitle style={{fontSize: "1.5rem"}}>Error 404</AlertTitle> */}
                
                
            </Alert>
        </div>
    )
}

export function BasicAlert(props){
    return (
        <p style={props.style} className="warning-msg" ><i className="fa-solid fa-triangle-exclamation"></i>{props.message}</p>
      );
} 
