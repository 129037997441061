import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { changePassword } from '../services/Service';
import { UserCreated } from './CreateUser'
import { resetPassword } from "../services/Service";
import { getRole } from './util';

export function FormDialog(props) {
  const [isResetPassword, setIsResetPassword] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState(null);

  const handleFormChange = (e) => {
    const val = e.target.value;
    const name = e.target.name;
    if (name === "fullname") {
      props.changeUpdates(prevValue => {
        return {
          fullname: val,
          email: prevValue.email,
          mobile: prevValue.mobile,
          iec_no: prevValue.iec_no
        }
      });
    }

    if (name === "iec") {
      props.changeUpdates(prevValue => {
        return {
          fullname: prevValue.fullname,
          email: prevValue.email,
          mobile: prevValue.mobile,
          iec_no: val
        }
      });
    }

    if (name === "mobile") {
      props.changeUpdates(prevValue => {
        return {
          fullname: prevValue.fullname,
          email: prevValue.email,
          mobile: val,
          iec_no: prevValue.iec_no
        }
      });
    }

  }

  const handleResetPassword = () => {
    const doReset = window.confirm("This will reset the password.\nAre you sure?")
    if (doReset) {
      // console.log("Reset password", props.currUser)
      const token = localStorage.getItem('token');
      resetPassword(token, props.currUser).then(response => {
        if (response){
          if (response.data){
            setUserDetails(response.data.data);
            setIsResetPassword(true);
          }
        }
      }, error => {
        console.log("error response: ", error.response)
        if (error.response){
            if (error.response.status === 403){
                localStorage.clear();
                window.location.href = "/"
            }
        }
        
    })
      
    } else {
      props.handleEditClose()
    }
  }

  return (
    <div>
        <Dialog open={props.openEditForm}>
          {isResetPassword ? null :
            <div>
            <DialogTitle>Update</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Update user details
              </DialogContentText>
              <TextField
                required
                onChange={handleFormChange}
                value={props.currUser ? props.currUser.fullname : ""}
                autoFocus
                margin="dense"
                id="name"
                label="Full name"
                name="fullname"
                type="text"
                fullWidth
                variant="standard"
              />
              {getRole() === "superadmin" ? <TextField
                required
                onChange={handleFormChange}
                value={props.currUser ? props.currUser.iec_no : ""}
                margin="dense"
                id="name"
                label="IEC No."
                name="iec"
                type="text"
                fullWidth
                variant="standard"
              /> : null}
              
              <TextField
                onChange={handleFormChange}
                value={props.currUser ? props.currUser.mobile : ""}
                margin="dense"
                id="mobile"
                name="mobile"
                label="Mobile"
                type="text"
                fullWidth
                variant="standard"
              />

            </DialogContent>


            <DialogActions>
              <DialogActions style={{ float: "left" }}>
                <Button onClick={handleResetPassword} style={{ float: "left", margin: "0", }}>Reset Password</Button>
              </DialogActions>

              <Button onClick={() => props.handleEditClose(false)}>Cancel</Button>
              <Button onClick={() => props.handleEditClose(true)}>Update</Button>
            </DialogActions>
          </div>
          }
          {isResetPassword ? <UserCreated type="reset" email={userDetails ? userDetails.email : ""} fullname={userDetails ? userDetails.fullname : ""} password={userDetails ? userDetails.password : ""} /> : null}
        </Dialog>
      
    </div>
  );
}


export function ResponsiveDialog(props) {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>

      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        // onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.type === "BILL" ? "This will delete the bill permanently." : "This will delete the user permanently."}
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { props.handleClose(true) }} autoFocus>
            yes
          </Button>
          <Button autoFocus onClick={() => { props.handleClose(false) }}>
            no
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
}

export function PasswordDialogBox(props) {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>

      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        // onClose={props.handlePasswordDialogBoxClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {"Please enter your password : "}
            <input autoComplete="off" className={"delete-btn"} style={{pointerEvent: 'none'}} onChange={props.handlePasswordInputChange} type="password" placeholder="Password*"></input>
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { props.handlePasswordDialogBoxClose(true) }} autoFocus>
            delete all
          </Button>
          <Button autoFocus onClick={() => { props.handlePasswordDialogBoxClose(false) }}>
            cancel
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
}

export function CheckboxConfirmationDialogBox(props) {

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>

      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        // onClose={props.handlePasswordDialogBoxClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <input type="checkbox" className={"delete-btn"} style={{cursor: "pointer"}} onChange={props.handleCheckboxInputChange}></input>
            &nbsp;{" I understand that the data cannot be recovered."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { props.handleCheckboxConfirmationDialogBoxClose(true) }} autoFocus>
            delete all
          </Button>
          <Button autoFocus onClick={() => { props.handleCheckboxConfirmationDialogBoxClose(false) }}>
            cancel
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
}


export function ChangePasswordDialog(props) {

  const [newPassword, setNewPassword] = React.useState({
    password: null,
    repeatPassword: null
  });

  const [msg, setMsg] = React.useState("");

  const handleFormChange = (e) => {
    const val = e.target.value;
    const name = e.target.name;

    if (name === "password") {
      setNewPassword(prevValue => {
        return {
          password: val,
          repeatPassword: prevValue.repeatPassword
        }
      })
    }

    if (name === "repeatPassword") {
      setNewPassword(prevValue => {
        return {
          password: prevValue.password,
          repeatPassword: val
        }
      })
    }
  }

  const updatePassword = () => {
    if (newPassword.password !== null && newPassword.repeatPassword !== null) {
      if (newPassword.password.length > 5 && newPassword.repeatPassword.length > 5) {

        if (newPassword.password === newPassword.repeatPassword) {
          setMsg(null);
          // console.log(newPassword);

          // Here call API
          const token = localStorage.getItem('token');

          changePassword(token, newPassword.password).then(response => {
            // console.log(response.data);
          })

          props.handleEditclose(true)
          setNewPassword({
            password: null,
            repeatPassword: null
          });
        }
        else {
          setMsg("Password didn't match")
        }
      } else {
        setMsg("Password should be of more then 5 letters")
      }
    } else {
      setMsg("Please fill required details*");
    }


  }
  return (
    <div>
      
      <Dialog open={props.openEditForm} onClose={props.handleEditclose}>
        <DialogTitle>Change password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Password should contain more than 5 letters.
          </DialogContentText>
          <TextField
            onChange={handleFormChange}
            autoFocus
            margin="dense"
            id="newPassword"
            name="password"
            label="New password"
            type="password"
            fullWidth
            variant="standard"
          />
          <TextField
            onChange={handleFormChange}
            name="repeatPassword"
            margin="dense"
            id="repeatPassword"
            label="Confirm new password"
            type="password"
            fullWidth
            variant="standard"
          />

        </DialogContent>
        <DialogContentText style={{ paddingLeft: "23px", height: "20px" }}>
          {msg}
        </DialogContentText>

        <DialogActions>
          <Button onClick={() => props.handleEditclose(null)}>Cancel</Button>
          <Button onClick={updatePassword}>Update</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
