import * as React from 'react'
import './App.css';
import Home from './components/Home';
import Login from './components/Login';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {CreateUser} from './components/CreateUser';
import UsersList from './components/UsersList';
import Dashboard from './components/Dashboard';
import BillsList from './components/BillsList';
import ExportBills from './components/ExportBills';
import CourierBills from './components/CourierBills';
import Account from './components/Account';
import { getRole } from './components/util';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";


function App() {
  const [role, setRole] = React.useState(null)

  React.useEffect(() =>{
    const role = getRole();
    setRole(role)
  }, [])

  return (
    <Router>
      <Routes>
        <Route path="/lucrative" element={<Home />}>
        
          <Route path="create" element={<CreateUser />} />
          <Route path="users" element={<UsersList />} />
          <Route path="import" element={<BillsList />} />
          <Route path="export" element={<ExportBills />} />
          <Route path="courier" element={<CourierBills />} />
          <Route path="account" element={<Account />} />
          <Route path="" element={<Dashboard />} />
        </Route> 
        <Route path="/" element={<Login navtype="signin"/>}/>
        <Route path="*" element={ <Navigate to="/" /> } />
      </Routes>
    </Router>
    
  );
}

export default App;
